import { fetchApi } from '../index';

export const GetMemberProfile = async (memberId) => {

    const url = '/member/v1/member/get_member_profile/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateMemberProfile = async (model) => {

    const url = '/member/v1/member/update_member_profile/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const AddOrUpdateMemberProfileNote = async (model) => {

    const url = '/member/v1/member_profile_note/add_or_update/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetMemberDemoRightCount = async (memberId) => {

    const url = '/member/v1/member_demo_right/get_member_demo_right_count/'

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}

export const UpdateMemberDemoRightCount = async (memberId, rightCount) =>  {

    const url = '/member/v1/member_demo_right/update_right_count/'

    const payload = {
        memberId: memberId,
        rightCount: rightCount
    };

    return await fetchApi(url, payload);
}

export const CheckIsDemoStudent = async (memberId) => {

    const url = '/member/v1/member/check_is_demo_student/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}