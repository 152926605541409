import {useEffect, useRef, useState} from "react";
import {LogoBeyaz} from "../../assets/svg";
import {NavbarProfile, NavbarReminder, NavbarTask, NavbarTicket} from '../navbar';
import {ChangeLanguage, closeInfo, toggleInfo} from "../../components";
import {useLocalization} from "../../hooks/Utils/useLocalization";
import useAuth from "../../hooks/Log/useAuth";
import {Link} from "react-router-dom";
import {url} from "../../routes/utility";
import OutsideClickHandler from "react-outside-click-handler";
import {GetNotAnswerCount} from "../../services/Ticket/TicketService";
import {GetActiveTaskCount} from "../../services/Task/TaskService";
import {GetReminderCount} from "../../services/Reminder/ReminderService";
import useAnalytics from "../../hooks/Log/useAnalytics";
import {ClickLogs} from "../../consts/Logs";
import NavbarReminderPopup from "./NavbarReminderPopup";

const Navbar = ({ navbarContentRef, navbarRef }) => {

  const strings = useLocalization();
  const { employee } = useAuth()
  const analytics = useAnalytics();

  const navbarTaskRef = useRef();
  const navbarTicketRef = useRef();
  const navbarReminderRef = useRef();
  const navbarProfileRef = useRef();
  const navbarPopupRef = useRef();

  const [ticketCount, setTicketCount] = useState(0);
  const [taskCount, setTaskCount] = useState(0);
  const [reminderCount, setReminderCount] = useState(0);
  const [isMuted, setIsMuted] = useState(false);

  //Navbarda bulunan bildirim butonlarının dışına tıklandığında kullanılıyor.
  const [isTaskOutside, setIsTaskOutside] = useState(false);
  const [isTicketOutside, setIsTicketOutside] = useState(false);
  const [isReminderOutside, setIsReminderOutside] = useState(false);
  const [isProfileOutside, setIsProfileOutside] = useState(false);

  //Navbarda bulunan bildirim butonların içeriklerinin açık/kapalı durumunu kontrol ediyor.
  const [isTaskOpen, setIsTaskOpen] = useState(false);
  const [isTicketOpen, setIsTicketOpen] = useState(false);
  const [isReminderOpen, setIsReminderOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  //Tooltipler için kullanılıyor
  const [isTaskHover, setIsTaskHover] = useState(false);
  const showTaskTooltip = isTaskHover ? "block" : "";
  const [isTicketHover, setIsTicketHover] = useState(false);
  const showTicketTooltip = isTicketHover ? "block" : "";
  const [isReminderHover, setIsReminderHover] = useState(false);
  const showReminderTooltip = isReminderHover ? "block" : "";
  const [alertInfo, setAlertInfo] = useState(null);

  //Cevaplanmayan ticket sayısını getiren fonksiyon
  const getTicketCount = () => {
    GetNotAnswerCount()
        .then(result => {
          if(result.status === 200){
            let resultContent = JSON.parse(result.content);
            setTicketCount(ticketCount => resultContent);
          }
        })
        .catch()
  }

  //Cevaplanmayan görev sayısını getiren fonksiyon
  const getTaskCount = (employeeId) => {
    GetActiveTaskCount(employeeId)
        .then(result => {
          if(result.status === 200){
            let resultContent = JSON.parse(result.content);
            setTaskCount(taskCount => resultContent);
          }
        })
        .catch()
  }

  //Bugüne ait hatırlatma sayısını getiren fonksiyon
  const getReminderCount = (employeeId) => {
    GetReminderCount(employeeId)
        .then(result => {
          if(result.status === 200){
            let resultContent = JSON.parse(result.content);
            setReminderCount(reminderCount => resultContent);
          }
        })
        .catch()
  }

  useEffect(() => {
    getTicketCount();
    getTaskCount(employee.EmployeeId);
    getReminderCount(employee.EmployeeId);
  },[])

  useEffect(() => {
    //tab değişikliğini bu alandan kontrol ediyoruz
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        getReminderCount(employee.EmployeeId);
      }
    }

    document.addEventListener("visibilitychange",  handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange",  handleVisibilityChange);
    }
  }, []);

  //Task butonunun dışına tıklandığında kapanmasını sağlıyor.
  useEffect(() => {
    if(isTaskOutside && isTaskOpen){
      setIsTaskOpen(!isTaskOpen);
      closeInfo(navbarTaskRef);
    }
  }, [isTaskOutside])

  //Ticket butonunun dışına tıklandığında kapanmasını sağlıyor.
  useEffect(() => {
    if(isTicketOutside && isTicketOpen){
      setIsTicketOpen(!isTicketOpen);
      closeInfo(navbarTicketRef);
    }
  }, [isTicketOutside])

  //Reminder butonunun dışına tıklandığında kapanmasını sağlıyor.
  useEffect(() => {
    if(isReminderOutside && isReminderOpen){
      setIsReminderOpen(!isReminderOpen);
      closeInfo(navbarReminderRef);
    }
  }, [isReminderOutside])

  //Profile butonunun dışına tıklandığında kapanmasını sağlıyor.
  useEffect(() => {
    if(isProfileOutside && isProfileOpen){
      setIsProfileOpen(!isProfileOpen);
      closeInfo(navbarProfileRef);
    }
  }, [isProfileOutside])

  const handleMute = (muted) => {
    setIsMuted(muted);
  };

  return (
    <>
      <div
        ref={navbarContentRef}
        id="content"
        className="flex flex-1 flex-col duration-500 "
      >
        <div>
          <div
            ref={navbarRef}
            id="navbar"
            className="sticky md:fixed md:w-[calc(100%-250px)] top-0 z-30 flex h-[62px] flex-shrink-0 bg-[#1269db] shadow-lg"
          >
            <button id="menu_mobile" className="ml-4 md:hidden max-sm:z-20">
              <span className="material-symbols-outlined text-white text-3xl menu-btn">
                menu
              </span>
            </button>
            <div className="flex flex-1 justify-between px-4 mx-3">
              <div className="flex flex-1">
                <form
                  className="w-full md:ml-0 my-3 hidden sm:flex"
                >
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-white focus-within:text-[#BFBFBF]">
                    <label
                      htmlFor="search-field"
                      className="cursor-pointer hover:opacity-80 absolute inset-y-0 left-0 flex items-center px-3"
                    >
                      <svg
                        className="h-5 w-5 mt-3"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </label>
                    <input
                      id="search-field"
                      className="block h-full w-full border-transparent bg-[#0F5ABC] focus-within:bg-white transition-colors duration-400 py-2 pl-14 pr-3 text-[#BFBFBF] placeholder-white focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm rounded-md"
                      placeholder={strings.navbar.search}
                      type="search"
                      name="search"
                    />
                  </div>
                </form>
                <Link
                  to={url("home")}
                  className="flex sm:hidden flex-shrink-0 items-center justify-center w-full mb-[3px] max-sm:z-20"
                >
                  <img
                    className="h-[33px] w-auto"
                    src={LogoBeyaz}
                    alt="ClickIVO Prime"
                  />
                </Link>
              </div>
              <label
                htmlFor="menu-toggle"
                className="ml-3 sm:hidden flex items-center max-sm:z-20"
              >
                <span className="material-symbols-outlined text-white text-3xl font-bold">
                  more_vert
                </span>
              </label>
              <input type="checkbox" id="menu-toggle" className="hidden peer" />
              <ul
                id="nav"
                className="ml-4 hidden sm:flex items-center max-sm:peer-checked:flex animate-slideIn toggle-content"
              >
                <li className="max-h-5 overflow-visible">
                  <ChangeLanguage />
                </li>
                <li>
                  <OutsideClickHandler onOutsideClick={() => setIsTaskOutside(true)}>
                    <div className="relative">
                      <button
                        onMouseEnter={() => setIsTaskHover(true)}
                        onMouseLeave={() => setIsTaskHover(false)}
                        onClick={() => {
                          setIsTaskOpen(!isTaskOpen);
                          setIsTaskOutside(false);
                          toggleInfo(navbarTaskRef);
                          //LOGGED
                          analytics.useAnalytics(ClickLogs.TASK_NOTIFICATION_CLICKED);
                        }}
                        type="button"
                        className="h-10 w-10 ml-1 rounded-sm hover:bg-[#0F5ABC] focus:bg-[#0F5ABC] flex items-center justify-center text-white relative"
                      >
                        <span className="sr-only">View notifications</span>
                        <span className="material-symbols-outlined">headset_mic</span>
                        <span className="w-4 h-4 rounded-full text-white absolute bg-green text-xs top-1 right-1">
                          {taskCount}
                        </span>
                      </button>
                      <div className={`tooltip ${showTaskTooltip}`}>
                        <p>{strings.navbar.tasks.title}</p>
                      </div>
                      <div
                        ref={navbarTaskRef}
                        className="hidden md:info animate-fadeIn md:absolute max-md:fixed max-md:mx-auto max-md:w-[90%] max-md:top-12 max-w-[300px] max-md:inset-0 md:right-0 z-10 min-h-max md:mt-[10px] w-72 origin-top-right text-[13px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <NavbarTask ref={navbarTaskRef} />
                      </div>
                    </div>
                  </OutsideClickHandler>
                </li>
                <li>
                  <OutsideClickHandler onOutsideClick={() => setIsTicketOutside(true)}>
                    <div className="relative">
                    <button
                        onMouseEnter={() => setIsTicketHover(true)}
                        onMouseLeave={() => setIsTicketHover(false)}
                        onClick={() => {
                          setIsTicketOpen(!isTicketOpen);
                          setIsTicketOutside(false);
                          toggleInfo(navbarTicketRef);
                          //LOGGED
                          analytics.useAnalytics(ClickLogs.TICKET_NOTIFICATION_CLICKED);
                        }}
                        type="button"
                        className="h-10 w-10 ml-1 rounded-sm hover:bg-[#0F5ABC] focus:bg-[#0F5ABC] flex items-center justify-center text-white relative"
                      >
                        <span className="sr-only">View notifications</span>
                        <span className="material-symbols-outlined">
                          confirmation_number
                        </span>
                        <span className="w-4 h-4 rounded-full text-white absolute bg-red text-xs top-1 right-1">
                          {ticketCount}
                        </span>
                      </button>
                      <div className={`tooltip ${showTicketTooltip}`}>
                        <p>{strings.navbar.tickets.title}</p>
                      </div>
                      <div
                        ref={navbarTicketRef}
                        className="hidden md:info animate-fadeIn md:absolute max-md:fixed max-md:mx-auto max-md:w-[90%] max-md:top-12 max-w-[300px] max-md:inset-0 md:right-0 z-10 min-h-max md:mt-[10px] w-72 origin-top-right text-[13px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <NavbarTicket ref={navbarTicketRef} />
                      </div>
                    </div>
                  </OutsideClickHandler>
                </li>
                <li>
                  <OutsideClickHandler onOutsideClick={() => setIsReminderOutside(true)}>
                    <div className="relative">
                      <button
                        onMouseEnter={() => setIsReminderHover(true)}
                        onMouseLeave={() => setIsReminderHover(false)}
                        onClick={() => {
                          setIsReminderOpen(!isReminderOpen);
                          setIsReminderOutside(false);
                          toggleInfo(navbarReminderRef);
                          //LOGGED
                          analytics.useAnalytics(ClickLogs.REMINDER_NOTIFICATION_CLICKED);
                        }}
                        type="button"
                        className="h-10 w-10 ml-1 rounded-sm hover:bg-[#0F5ABC] focus:bg-[#0F5ABC] flex items-center justify-center text-white relative"
                      >
                        <span className="sr-only">View notifications</span>
                        <span className="material-symbols-outlined">notifications</span>
                        <span className="w-4 h-4 rounded-full text-white absolute bg-warning text-xs top-1 right-1">
                          {reminderCount}
                        </span>
                      </button>
                      <div className={`tooltip ${showReminderTooltip}`}>
                        <p>{strings.navbar.reminders.title}</p>
                      </div>

                      <div
                        ref={navbarReminderRef}
                        className="hidden md:info animate-fadeIn md:absolute max-md:fixed max-md:mx-auto max-md:w-[90%] max-md:top-12 max-w-[300px] max-md:inset-0 md:right-0 z-10 min-h-max md:mt-[10px] w-72 origin-top-right text-[13px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <NavbarReminder 
                          ref={navbarReminderRef} 
                          popupRef={navbarPopupRef} 
                          setAlertInfo={setAlertInfo} 
                          isMuted={isMuted} 
                        />
                      </div>
                    </div>
                  </OutsideClickHandler>
                </li>
                <li>
                  <OutsideClickHandler onOutsideClick={() => setIsProfileOutside(true)}>
                    <div className="relative ml-3">
                      <button
                        onClick={() => {
                          setIsProfileOpen(!isProfileOpen);
                          setIsProfileOutside(false);
                          toggleInfo(navbarProfileRef);
                        }}
                        type="button"
                        className="group flex max-w-xs items-center rounded-full"
                      >
                         <img
                          className="h-10 w-10 rounded-full"
                          src={process.env.REACT_APP_PROFILE_URL+ "/employee/" + employee.ProfilePicture}
                          alt="Profil"
                        />
                      </button>
                      <div
                        id="info"
                        ref={navbarProfileRef}
                        className="hidden md:info py-4 animate-fadeIn md:absolute max-md:fixed max-md:mx-auto max-md:w-[90%] max-md:top-12 max-w-max max-md:inset-0 md:right-0 z-10 min-h-max md:mt-[10px] w-[1000px] origin-top-right text-[13px] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <NavbarProfile ref={navbarProfileRef} />
                      </div>
                    </div>
                  </OutsideClickHandler>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>         
      <NavbarReminderPopup ref={navbarPopupRef} alertInfo={alertInfo} onMute={handleMute} />
      <div id="modal-start-task"></div>
    </>
  );
};

export default Navbar;