import classNames from "classnames";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {
  activeAdditionalInformationTab,
  activeDiscountTab,
  Button,
  Dropdown,
  DropdownMultiSelect,
  Input,
  LoadingSpinner,
  openAdditionalInformationTab,
  openDiscountTab,
  PageTitle,
  showCenteredAlertTwoButtons,
  showCenteredAlertTwoButtonsWithParameters,
  showCenteredHtmlAlert,
  showTopAlert
} from "../../../../../components";
import {
  customDecodeURI,
  getBrandNameById,
  getLanguageTypeByLanguageKey,
  isItSpecified,
  ISOStringToDate,
  ISOStringToDateWithTime,
  removeHtmlTags,
  validateEmail
} from "../../../../../components/Utility";
import {
  AdditionalInformations,
  Brands,
  CampaignEndDate,
  CampaignTypes,
  MemberStatus,
  PackageRightsItem,
  SalesTypeForGiveOffer
} from "../../../../../consts/Constants";
import {CustomEventDescriptions, CustomEvents} from "../../../../../consts/DataLayer";
import {ClickLogs} from "../../../../../consts/Logs";
import {Notes} from "../../../../../consts/Notes";
import useEmployeeSenderEmail from "../../../../../hooks/Employee/useEmployeeSenderEmail";
import useAnalytics from "../../../../../hooks/Log/useAnalytics";
import useAuth from "../../../../../hooks/Log/useAuth";
import useCreateMemberNote from "../../../../../hooks/Log/useCreateMemberNote";
import useDataLayer from "../../../../../hooks/Log/useDataLayer";
import useMemberBrand from "../../../../../hooks/Member/useMemberBrand";
import useMemberEmail from "../../../../../hooks/Member/useMemberEmail";
import useMemberPhoneNumber from "../../../../../hooks/Member/useMemberPhoneNumber";
import useMemberStatus from "../../../../../hooks/Member/useMemberStatus";
import {useLocalization} from "../../../../../hooks/Utils/useLocalization";
import {GetBankInfoDetailsByBrandId} from "../../../../../services/Bank/BankService";
import {EmployeeDetails} from "../../../../../services/Employee/EmployeeService";
import {
  GetMailTemplateByMemberOrEmployeeIdAndMailType,
  SendEmailModel
} from "../../../../../services/Information/InformationService";
import {
  CalculateDiscountedPriceOfGiveOfferPackage,
  CalculateGiveOfferProductPrice,
  GetCampaignTypeDetailsForSales,
  GetDiscountsByMemberId,
  GetProductCategorySelectList,
  GetSalesProductDetails,
  GetSalesProductsV3
} from "../../../../../services/Product/ProductService";
import {GetFamilyDiscountRates, GetHourlyDiscountRates} from "../../../../../services/Sales/SalesService";
import { getTimezone } from "countries-and-timezones";

const SendOffer = () => {

  const strings = useLocalization();
  const notSpecified = strings.errors.it_is_not_specified;
  const language = useSelector(state => state.localizationStore.language);

  /* Hooks */
  const analytics = useAnalytics();
  const dataLayer = useDataLayer();
  const memberNote = useCreateMemberNote();
  const { memberId } = useParams() || false;
  const { employee } = useAuth();
  const employeeSenderEmail = useEmployeeSenderEmail(employee?.EmployeeId);
  const memberStatus = useMemberStatus(memberId) || false;
  const brandId = useMemberBrand(memberId) || false;
  const memberPhoneNumber = useMemberPhoneNumber(memberId);
  const currentEmail = useMemberEmail(memberId);
  /* Hooks */

  const topRef = useRef();
  const discountTabRef = useRef();
  const additionalInformationTabRef = useRef();
  const discountButtonRef = useRef();
  const additionalInformationButtonRef = useRef();

  //Satış tipi alanı için stateler
  const firstSalesType = { id: -1, name: strings.member.member_detail.inform_operations.choose_sell_type };
  const salesTypes = SalesTypeForGiveOffer.map((salesType) => ({ id: salesType.id, name: salesType[language] }));

  //let salesTypeList = [firstSalesType, ...salesTypes];
  const [salesTypeList, setSalesTypeList] = useState([]);
  const [salesType, setSalesType] = useState(firstSalesType);

  //Eğitim paketi alanı için statleler
  const firstProductCategory = { id: -1, name: strings.member.member_detail.inform_operations.choose_education_package };
  //let productCategoryList = [firstProductCategory, ...primeData.productCategoryList];
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [productCategory, setProductCategory] = useState(firstProductCategory);

  //Eğitim süresi alanı için stateler
  const firstMonth = { id: -1, name: strings.member.member_detail.inform_operations.choose_education_time };
  const [month, setMonth] = useState(firstMonth);
  const [monthList, setMonthList] = useState([]);

  //Haftalık ders saati alanı için stateler
  const firstDayOfWeek = { id: -1, name: strings.member.member_detail.inform_operations.choose_weekly_lesson_count };
  const [dayOfWeek, setDayOfWeek] = useState(firstDayOfWeek);
  const [dayOfWeekList, setDayOfWeekList] = useState([]);

  //Ders süresi alanı için stateler
  const firstMinute = { id: -1, name: strings.member.member_detail.inform_operations.choose_lesson_time };
  const [minute, setMinute] = useState(firstMinute);
  const [minuteList, setMinuteList] = useState([]);
  const [tempMinuteList, setTempMinuteList] = useState([]);

  //Seçilen ürün bilgileri için state
  const [selectedProduct, setSelectedProduct] = useState({});

  //Tüm indirimleri hesaplamak için kullanılan state
  const [allDiscountDetailsList, setAllDiscountDetailsList] = useState([]);
  const [allAppliedDiscounts, setAllAppliedDiscounts] = useState([]);

  //Pakete özel kampanya alanı için stateler
  const firstCampaign = { id: -1, name: strings.member.member_detail.sales_operations.choose_special_campaign };
  const [campaign, setCampaign] = useState(firstCampaign);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignListWithDetail, setCampaignListWithDetail] = useState([]);

  //Kumbara indirimi alanı için stateler
  const firstMoneyboxDiscount = { id: -1, name: strings.member.member_detail.inform_operations.choose_moneybox_discount };
  const [moneyboxDiscount, setMoneyboxDiscount] = useState(firstMoneyboxDiscount);
  const [moneyboxDiscountList, setMoneyboxDiscountList] = useState([]);

  //Referans indirimi alanı için stateler
  const firstReferenceDiscount = { id: -1, name: strings.member.member_detail.inform_operations.choose_reference_discount };
  const [referenceDiscount, setReferenceDiscount] = useState(firstReferenceDiscount);
  const [referenceDiscountList, setReferenceDiscountList] = useState([]);

  //Diğer indirim alanı için stateler
  const [otherDiscounts, setOtherDiscounts] = useState([]);
  const [otherDiscountsList, setOtherDiscountsList] = useState([]);
  const [fourtyEightHoursDiscountContent, setFourtyEightHoursDiscountContent] = useState("");

  //Saatlik indirim alanı için stateler
  const [hourDiscountList, setHourDiscountList] = useState([]);
  const [selectedHourDiscount, setSelectedHourDiscount] = useState([]);

  //Ödeme tutarı alanı için stateler
  const firstPaymentPrice = { id: -1, name: strings.member.member_detail.inform_operations.choose_payment_price };
  const [paymentPrice, setPaymentPrice] = useState(firstPaymentPrice);
  const [productPriceList, setProductPriceList] = useState([]);
  const [productPriceListWithDiscount, setProductPriceListWithDiscount] = useState([]);
  const [productPrice, setProductPrice] = useState({});
  const [productPriceWithDiscount, setProductPriceWithDiscount] = useState({});

  //Toplam indirim ve seçilen tüm paketler için kullanılan stateler
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedNotes, setSelectedNotes] = useState([]);

  //Mail template'i için stateler
  const [tmpContent, setTmpContent] = useState(null);
  const [content, setContent] = useState(null);

  //WhatsApp template'i için stateler
  const [tmpWhatsappContent, setTmpWhatsappContent] = useState(null);
  const [whatsappContent, setWhatsappContent] = useState(null);

  //Ek bilgiler tab'ı için stateler
  const additionalInformationList = AdditionalInformations;
  const [selectedAdditionalInformation, setSelectedAdditionalInformation] = useState([]);
  const [bankInformations, setBankInformations] = useState([]);

  //Employee informations için state
  const [employeeInformations, setEmployeeInformations] = useState({});

  const [otherEmail, setOtherEmail] = useState("");
  const [sendEmailButtonLoading, setSendEmailButtonLoading] = useState(false);

  const getProductCategoryList = async (language) => {
    const result = await GetProductCategorySelectList(language);

    let arrayList = [];

    if (result.status === 200) {

      const data = JSON.parse(result.content);

      data.map((item) => {
        if (+brandId !== Brands.KO && +item.value === 3) return;

        let arrayItem = { id: parseInt(item.value), name: item.text };
        arrayList.push(arrayItem);
      })
    }

    setProductCategoryList(productCategoryList => arrayList);
  }

  //Seçilen pakete göre dakika ve ders süresi bilgileri getiriliyor
  const getSalesProducts = async (productCategoryId, brandId, salesType) => {

    let tmpProductMonths = [];
    let tmpProductDays = [];
    let tmpProductMinutes = [];

    let model = {
      productCategoryId: productCategoryId,
      brandId: brandId,
      salesType: salesType.id
    }

    const result = await GetSalesProductsV3(model);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      resultContent?.productMonthList.map((item) => {
        tmpProductMonths.push({ id: +item.id, name: item.name });
      })

      resultContent?.productDayList.map((item) => {
        tmpProductDays.push({ id: +item.id, name: item.name });
      })

      resultContent?.productMinuteList.map((item) => {
        tmpProductMinutes.push({ id: +item.id, name: item.name });
      })

      setMonthList([firstMonth, ...tmpProductMonths]);
      setDayOfWeekList([firstDayOfWeek, ...tmpProductDays]);
      setMinuteList([firstMinute, ...tmpProductMinutes]);
      setTempMinuteList([...tmpProductMinutes]);

    }
  }

  //Seçilen paket detayları getiriliyor
  const getSalesProductDetails = async (model) => {
    const result = await GetSalesProductDetails(model);

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      setSelectedProduct(data);
    }
  }

  //Seçilen ürüne ait kampanya varsa kampanyaları getiriliyor
  const getCampaignTypeDetails = async (productId, salesTypeId) => {

    const result = await GetCampaignTypeDetailsForSales(productId, salesTypeId);

    let arrayList = [];
    let arrayListWithDetail = [];

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      data.map((item) => {
        let arrayItem = { id: item.campaignId, name: item.campaignName + " - %" + item.discountRate };
        arrayList.push(arrayItem);
        arrayListWithDetail.push(item);
      })
    }

    setCampaignList([firstCampaign, ...arrayList]);
    setCampaignListWithDetail(arrayListWithDetail);
  }

  //Member'a tüm ait indirimler getiririliyor.
  const getDiscountsByMemberId = async (model) => {
    const result = await GetDiscountsByMemberId(model);

    //Member'a ait 4 adet indirim türü var.
    //Her biri için ayrı ayrı array oluşturuluyor.
    let moneyboxList = [];
    let referenceList = [];
    //Kampanya tipi 2 ve 3 olanlar için array
    let otherDiscountsList = [];
    let allDiscountDetailsList;

    if (result.status === 200) {
      const data = JSON.parse(result.content);
      // Bu servisten eğer aile indirimi tipinde bir kampanya gelirse, onu çiftlememek için filtreliyoruz
      allDiscountDetailsList = data.filter(i => !i.campaignName.toLowerCase().includes("aile indirimi"));    

      //Kampanya tipine göre gruplama yapılıyor.
      data.map((item) => {
        //Kumbara indirimi listesi oluşturuluyor.
        if (item.campaignType === 0) {

          let moneyboxItem = { id: item.campaignId, name: item.campaignName + " - %" + item.discountRate, campaignCode: item.campaignCode, campaignEndDate: item.campaignEndDate };
          moneyboxList.push(moneyboxItem);

        }
        //Referans indirimi listesi oluşturuluyor.
        else if (item.campaignType === 1) {

          let referenceItem = { id: item.campaignId, name: item.campaignName + " - %" + item.discountRate, campaignCode: item.campaignCode, campaignEndDate: item.campaignEndDate };
          referenceList.push(referenceItem);

        }
        //Diğer indirimler listesi oluşturuluyor.
        else if (item.campaignType === 2 || item.campaignType === 3 || item.campaignType === 4) {

          let otherDiscountItem = { id: item.campaignId, name: item.campaignName + " - %" + item.discountRate, campaignCode: item.campaignCode, campaignEndDate: item.campaignEndDate };
          otherDiscountsList.push(otherDiscountItem);
        }
      })

      let defaultReferenceDiscountArray = {
        campaignId: "",
        campaignName: "%10 Referans İndirimi",
        discountRate: 10,
        campaignCode: "",
        campaignType: 1,
        campaignEndDate: "0001-01-01T00:00:00"
      };

      const tempHourDiscountList = hourDiscountList
        .filter(h => h.id !== -1)
        .map((item) => {
          return {
            campaignId: item.id,
            campaignName: item.name,
            discountRate: item.discountRate,
            campaignCode: "",
            campaignType: 2,
            campaignEndDate: "0001-01-01T00:00:00"
          }
        });

      let referenceDiscount = data.find((item) => item.campaignType === CampaignTypes.Reference) || {}

      if (Object.keys(referenceDiscount).length === 0) {
        referenceList.push({ id: defaultReferenceDiscountArray.campaignId, name: defaultReferenceDiscountArray.campaignName + " - %" + defaultReferenceDiscountArray.discountRate, campaignCode: defaultReferenceDiscountArray.campaignCode, campaignEndDate: defaultReferenceDiscountArray.campaignEndDate });
        allDiscountDetailsList = [...allDiscountDetailsList, defaultReferenceDiscountArray, ...tempHourDiscountList]
      }
    }

    setMoneyboxDiscountList([firstMoneyboxDiscount, ...moneyboxList]);
    setReferenceDiscountList([firstReferenceDiscount, ...referenceList]);
    setOtherDiscountsList(otherDiscountsList);
    setAllDiscountDetailsList(allDiscountDetailsList);
  }

  //Ürün fiyatını hesaplar ve 'Ödeme Tutarı' listesine ekler.
  const calculateProductPrice = async (model) => {
    const result = await CalculateGiveOfferProductPrice(model);

    /*
      Servisten gelen taksit bilgileri sabit olduğu için ve bir obje içerisinden geldiği için ödeme tutarı
      listemizi taksit sayılarına göre manuel olarak oluşturduk.
    */
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setProductPrice(productPrice => resultContent);
    }
  }

  //Bir indirim seçildiğinde indirimli fiyatı hesaplar ve 'Ödeme Tutarı' listesine ekler.
  const calculateProductPriceWithDiscount = async (model) => {

    const result = await CalculateDiscountedPriceOfGiveOfferPackage(model);

    /*
      Servisten gelen taksit bilgileri sabit olduğu için ve bir obje içerisinden geldiği için ödeme tutarı
      listemizi taksit sayılarına göre manuel olarak oluşturduk.
      Eğer herhangi bir indirim seçildiyse bu hesaplama çalışıyor.
    */
    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setProductPriceWithDiscount(productPriceWithDiscount => resultContent);
    }
  }

  //Mail template bilgisini getirir.
  const getMailTemplate = async () => {

    let model = {
      type: 1,
      memberOrEmployeeId: memberId
    };

    const result = await GetMailTemplateByMemberOrEmployeeIdAndMailType(model);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setTmpContent(tmpContent => resultContent.MailHtml);
      setTmpWhatsappContent(tmpWhatsappContent => resultContent.WhatsappContent);
    }
  }

  //Ek bilgilendirme kısmında bulunan 'Banka havale bilgierini gönder" kısmı için banka bilgileri getiriliyor.
  const getBankInformations = async (brandId) => {

    const result = await GetBankInfoDetailsByBrandId(brandId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);
      setBankInformations(bankInformations => resultContent);
    }
  }

  //Mail template'ine eklemek için kullanıcı bilgilerini getirir.
  const getEmployeeDetails = async (employeeId) => {

    const result = await EmployeeDetails(employeeId);

    if (result.status === 200) {
      let resultContent = JSON.parse(result.content);

      setEmployeeInformations(employeeInformations => resultContent);
    }
  }

  //Kullanıcının seçtiği paketler bir listeye ekleniyor.
  const handleAddPackage = () => {

    let valid = true;
    let errorList = '';

    //Boş geçilmeyen alanaların kontrolü yapılıyor.
    if (salesType.id === -1) {
      valid = false;
      errorList += strings.errors.sales_type_null_error + "<br />";
    }
    if (productCategory.id === -1) {
      valid = false;
      errorList += strings.errors.select_product_null_error + "<br />";
    }
    if (month.id === -1) {
      valid = false;
      errorList += strings.errors.select_product_time_null_error + "<br />";
    }
    if (dayOfWeek.id === -1) {
      valid = false;
      errorList += strings.errors.weekly_lesson_count_null_error + "<br />";
    }
    if (minute.id === -1) {
      valid = false;
      errorList += strings.errors.lesson_minute_null_error + "<br />";
    }
    if (paymentPrice.id === -1) {
      valid = false;
      errorList += strings.errors.payment_price_null_error + "<br />";
    }

    if (valid) {

      //Herhangi bir indirim seçiliyse indirimli fiyat, herhangi bir indirim seçilmediyse indirimsiz fiyat ekleniyor.
      let isDiscountSelected = campaign.id !== -1 || moneyboxDiscount.id !== -1 || referenceDiscount.id !== -1 || otherDiscounts.length > 0 || selectedHourDiscount.length > 0;
      let isOtherDiscountSelected = otherDiscounts.length > 0;
      let packageRightsItem = PackageRightsItem(brandId)
        .replace("#postponeRightCount#", selectedProduct.postponeRightCount)
        .replace("#teacherHourChangeRightCount#", selectedProduct.teacherHourChangeRightCount)
        .replace("#missedRightCount#", selectedProduct.missedRightCount)
        .replace("#freezeRightCount#", selectedProduct.freezeRightCount)

      let appliedDiscountText = ""
      if (allAppliedDiscounts.length > 0) {
        appliedDiscountText += `<b>Uygulanan İndirimler</b><br />`
        allAppliedDiscounts.map((appliedDiscount) => {
          appliedDiscountText += `<span><b>${appliedDiscount.discountRate}% -</b> ${appliedDiscount.campaignName}</span><br />`
        })
      }

      let arrayItem = `${productCategory.name} ${month.name} ${dayOfWeek.name} ${minute.name} - ${isDiscountSelected ? `${language === 'tr' ? `%${totalDiscount} indirim ile` : `with ${totalDiscount}% discount`}` : ""} ${paymentPrice.name} ${isOtherDiscountSelected ? fourtyEightHoursDiscountContent : ""} <br /><br /> ${packageRightsItem} ${appliedDiscountText.length > 0 ? '<br />' : ''} ${appliedDiscountText}`
      let tempNoteContent = `${productCategory.name} ${month.name} ${dayOfWeek.name} ${minute.name} - ${isDiscountSelected ? `${language === 'tr' ? `%${totalDiscount} indirim ile` : `with ${totalDiscount}% discount`}` : ""} ${paymentPrice.name} ${isOtherDiscountSelected ? fourtyEightHoursDiscountContent : ""} ${appliedDiscountText.length > 0 ? '<br />' : ''} ${appliedDiscountText}`

      setSelectedPackages(selectedPackages => [...selectedPackages, arrayItem]);
      setSelectedNotes(selectedNotes => [...selectedNotes, tempNoteContent]);
      clearCampaignStates();
      setAllAppliedDiscounts([]);
    } else {
      showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error");
    }
  }

  //X işaretine basılınca paketleri template'den kaldırıyoruz.
  const deletePackage = (index) => {
    const tempData = [...selectedPackages];
    const tempNoteContentData = [...selectedNotes];

    tempData.splice(index, 1);
    tempNoteContentData.splice(index, 1);

    setSelectedPackages([...tempData]);
    setSelectedNotes([...tempNoteContentData]);
  };

  //Paket ekle işlemi yapıldıktan sonra, paket eklemek için kullanılan alanların sıfırlanması için kullanılıyor.
  const clearStates = () => {
    setSalesType(firstSalesType);
    setProductCategory(firstProductCategory);
    setMonth(firstMonth);
    setDayOfWeek(firstDayOfWeek);
    setMinute(firstMinute);
    setCampaign(firstCampaign);
    setMoneyboxDiscount(firstMoneyboxDiscount);
    setReferenceDiscount(firstReferenceDiscount);
    setOtherDiscounts([]);
    setPaymentPrice(firstPaymentPrice);
    setSelectedHourDiscount([]);
  }

  const clearCampaignStates = () => {
    setCampaign(firstCampaign);
    setMoneyboxDiscount(firstMoneyboxDiscount);
    setReferenceDiscount(firstReferenceDiscount);
    setOtherDiscounts([]);
    setPaymentPrice(firstPaymentPrice);
    setSelectedHourDiscount([]);
  }

  //Ek bilgilendirme alanı için seçilen alanları bir listeye ekliyoruz.
  const handleChoose = (item) => {
    if (selectedAdditionalInformation.includes(item)) {
      setSelectedAdditionalInformation(selectedAdditionalInformation.filter((selectedId) => selectedId !== item));
    } else {
      setSelectedAdditionalInformation([...selectedAdditionalInformation, item]);
    }
  };

  //Ek bilgilendirme alanının CSS kodları kontrol ediliyor.
  const handleSelectedCheckbox = (id) => {
    if (selectedAdditionalInformation.includes(id)) {
      return 'bg-green border border-green text-white'
    } else {
      return 'border border-[#aaaaaa]'
    }
  };

  const getEmailTitleTranslation = (brandId) => {
    switch (brandId) {
      case 1:
        return "Paket Teklifi"
      case 2:
        return "Paket təklifi"
      case 3:
        return "عرض الباقة"
      default:
        return "Paket Teklifi"
    }
  }

  //E-Posta gönder alanı için kullanılıyor.
  const handleEmail = () => {

    let valid = true;
    let errorList = '';
    //Kullanıcının birden fazla e-posta adresine posta gönderildiği için e-posta adresleri bir array'de tutuluyor.
    let emailList = [];

    //Kullanıcının hali hazırda bir e-posta adresi yok ise 'diğer e-posta adresi alanı zorunlu hale getiriliyor.
    if ((currentEmail === 'Belirtilmedi' || currentEmail === '') && otherEmail === '') {
      valid = false;
      errorList += strings.errors.email_null_error + "<br />";
    }
    //Diğer e-posta adresine herhangi bir değer girildiyse e-posta formatına uygun olup olmadığı kontrol ediliyor.
    if (otherEmail.length > 0 && !validateEmail(otherEmail)) {
      valid = false;
      errorList += strings.errors.email_format_error + "<br />";
    }

    if (valid) {

      //Eğer kullanıcının hali hazırda bir mail adresi yoksa diğer e-posta adresi alanı e-posta listesine ekleniyor.
      if (currentEmail === 'Belirtilmedi' || currentEmail === '') {
        emailList.push(otherEmail);
      }
      //İki farklı adrese mail gönderiliyorsa iki adreste e-posta listesine ekleniyor.
      else if (otherEmail.length > 0) {
        emailList.push(currentEmail, otherEmail);
      }
      else {
        emailList.push(currentEmail);
      }

      //Mail template alanı alınıyor.
      let mailContent = document.getElementById("contentDiv").innerHTML;

      //Mail göndermek için kullanılan servise model oluşturuluyor.
      const model = {
        subject: getEmailTitleTranslation(brandId),
        content: mailContent,
        recipients: emailList,
        from: employeeSenderEmail,
        name: getBrandNameById(brandId),
        emailServiceType: 2, //PostMark için enum değeri
        persistentContent: 1 //WithoutContent için enum değeri
      };

      showCenteredAlertTwoButtonsWithParameters(
        strings.alert.centered_alert_two_buttons.title_confirm,
        strings.member.member_detail.inform_operations.send_email_question,
        strings.button.confirm,
        strings.button.cancel,
        sendEmail,
        [model]
      );
    } else {
      showCenteredHtmlAlert(strings.errors.an_error_occurred, errorList, "error");
    }
  }

  const handleSendWhatsappMessage = () => {
    showCenteredAlertTwoButtons(
      strings.alert.centered_alert_two_buttons.title_confirm,
      strings.member.member_detail.inform_operations.send_whatsapp_message,
      strings.button.confirm,
      strings.button.cancel,
      sendWhatsappMessage
    );
  }

  //E-Posta gönderiliyor.
  const sendEmail = async (model) => {
    setSendEmailButtonLoading(sendEmailButtonLoading => true);

    const result = await SendEmailModel(model);
    /*
      E-Posta başarılı şekilde gönderildiyse paketler için olan tüm state'ler sıfırlanıyor.
      Ek bilgilendirme alanı için olan stateler sıfırlanıyor.
      Seçilen paketlerin tutulduğu liste sıfırlanıyor.
      İndirimler tab'ı açılıyor.
    */
    if (result.status === 200) {
      clearStates();
      setSelectedAdditionalInformation([]);
      setSelectedPackages([]);
      setSelectedNotes([]);
      activeDiscountTab(discountButtonRef, additionalInformationButtonRef);
      openDiscountTab(discountTabRef, additionalInformationTabRef);
      topRef.current.focus();

      showTopAlert(strings.member.member_detail.inform_operations.email_success_message, 'success');

      //LOGGED
      analytics.useAnalytics(ClickLogs.OFFER_MAIL_WAS_SEND, { memberId: memberId });

      //DATA LAYER LOGGED
      dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.OFFER_SENDED);

      let noteText = ""

      selectedNotes.map((item, index) => {
        noteText += `${index === 0 ? '<br/><br/>' : ''} <b>${index + 1})</b> ${item} ${index !== selectedNotes.length - 1 ? '<br/><br/>' : ''}`
      }
      );

      //NOTED
      memberNote.useCreateMemberNote(
        memberId,
        Notes.SEND_OFFER_MAIL
          .replace("{consultantName}", employee?.FullName)
          .replace("{content}", noteText),
        43 //default
      );

    } else {
      //LOGGED
      analytics.useAnalytics(ClickLogs.EMAIL_WAS_NOT_SEND, { memberId: memberId });

      if (currentEmail.length === 0)
        showTopAlert(strings.member.member_detail.inform_operations.member_email_not_found, "error");
      else
        showTopAlert(strings.errors.an_error_occurred, "error");
    }

    setSendEmailButtonLoading(sendEmailButtonLoading => false);
  }

  const sendWhatsappMessage = () => {
    /*
     WP template'i getirilip HTML taglarinden arındılıyor.
     Arındırmadan önce <br /> yerine alt satıra inmek için özel karakterleri koyuyoruz.
     Base URL ile birleştirilip WP ekranı yan sekmede açılıyor
    */
    let cleanedWhatsappContent = removeHtmlTags(whatsappContent.replaceAll("<br />", "%0A").replaceAll("<hr class='my-2' />", "%0A ------------------------- %0A%0A"))
    let whatsappApiUrl = process.env.REACT_APP_WHATSAPP_API_URL + `/?phone=${memberPhoneNumber}&text=${cleanedWhatsappContent}`;

    clearStates();
    setSelectedAdditionalInformation([]);
    setSelectedPackages([]);
    setSelectedNotes([]);
    activeDiscountTab(discountButtonRef, additionalInformationButtonRef);
    openDiscountTab(discountTabRef, additionalInformationTabRef);
    topRef.current.focus();

    //LOGGED
    analytics.useAnalytics(ClickLogs.OFFER_WAS_SEND_ON_WHATSAPP, { memberId: memberId });

    //DATA LAYER LOGGED
    dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.OFFER_SENDED);

    let noteText = ""

    selectedNotes.map((item, index) => {
      noteText += `${index === 0 ? '<br/><br/>' : ''} <b>${index + 1})</b> ${item} ${index !== selectedNotes.length - 1 ? '<br/><br/>' : ''}`
    }
    );

    //NOTED
    memberNote.useCreateMemberNote(
      memberId,
      Notes.SEND_OFFER_MAIL_WHATSAPP
        .replace("{consultantName}", employee?.FullName)
        .replace("{content}", noteText),
      43 //default
    );

    //WhatsApp ekranı yeni sekmede açılıyor
    window.open(whatsappApiUrl);
  }

  //Sayfa ilk yüklendiğinde member'a ait brandId ve e-posta adresi getiriliyor.
  useEffect(() => {
    getMailTemplate();
    getEmployeeDetails(employee.EmployeeId);
  }, [])

  //Eğer diğer indirimlerden bir 48 saat indirimi seçilirse bu indirimin son kullanma tarihini 
  //mail template'ine eklemek için bir değişken tutuyoruz
  useEffect(() => {
    let fourtyEightHoursDiscount = otherDiscounts.find((otherDiscount) => otherDiscount?.campaignCode?.startsWith("48"));
    if (otherDiscountsList.length > 1 && fourtyEightHoursDiscount) {
      let campaignEndDate = CampaignEndDate(brandId)
        .replace("#campaignCode#", fourtyEightHoursDiscount.campaignCode)
        .replace("#date#", ISOStringToDate(fourtyEightHoursDiscount.campaignEndDate, employee?.TimeZone))
      setFourtyEightHoursDiscountContent(fourtyEightHoursDiscountContent => ` <br /> ${campaignEndDate}`)
    } else {
      setFourtyEightHoursDiscountContent(fourtyEightHoursDiscountContent => "");
    }
  }, [otherDiscounts])

  useEffect(() => {
    if (language && brandId) {
      getProductCategoryList(language);
    }
  }, [language, salesType, brandId])

  // Sales type listesi öğrenci status'üne göre manipüle ediliyor.
  useEffect(() => {
    let tmpSalesTypeList = [];

    if (memberStatus !== MemberStatus.Candidate) {
      tmpSalesTypeList = [firstSalesType, ...salesTypes.filter(type => type.id !== 1)]
    } else {
      tmpSalesTypeList = [firstSalesType, ...salesTypes.filter(type => type.id === 1)]
    }

    setSalesTypeList(tmpSalesTypeList);
  }, [memberStatus])

  //Öğrenci adaylarında yeni kayıt seçili getiriliyor.
  useEffect(() => {
    if (salesTypeList.length > 0 && memberStatus === MemberStatus.Candidate) {
      let tmpSelectedSalesType = salesTypeList.find(st => st.id === MemberStatus.Candidate);
      setSalesType(salesType => tmpSelectedSalesType);
    }
  }, [salesTypeList]);

  //Seçili bir paket varsa pakete göre fiyatlar getiriliyor.
  useEffect(() => {
    if (Object.keys(selectedProduct).length > 0) {
      let model = {
        productId: selectedProduct.productId,
        productDayCount: dayOfWeek.id,
        productCategoryId: productCategory.id,
        memberId: memberId
      };

      calculateProductPrice(model);
    }
  }, [selectedProduct])

  useEffect(() => {
    let priceArrayList = [];
    let priceWithDiscountArrayList = [];

    Object.keys(productPrice).length > 0 && (
      //Eğer candidatePriceDetail objesi varsa dropdown'da ikinci kayıt indirimi uygulanmış olarak gösteriyoruz
      productPrice.candidatePriceDetail
        ? priceArrayList.push(
          { id: 1, name: `${strings.member.member_detail.sales_operations.one_shot} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPrice.priceDetail.totalPrice})` },
          { id: 3, name: `₺${productPrice.threeInstallementPrice} X 3 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPrice.priceDetail.totalPrice})` },
          { id: 6, name: `₺${productPrice.sixInstallementPrice} X 6 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPrice.priceDetail.totalPrice})` },
          { id: 9, name: `₺${productPrice.nineInstallementPrice} X 9 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPrice.priceDetail.totalPrice})` },
          { id: 12, name: `₺${productPrice.twelveInstallementPrice} X 12 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPrice.priceDetail.totalPrice})` }
        )
        : priceArrayList.push(
          { id: 1, name: `${strings.member.member_detail.sales_operations.one_shot} ${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice}` },
          { id: 3, name: `${productPrice.priceDetail.currencySymbol}${productPrice.threeInstallementPrice} X 3 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice})` },
          { id: 6, name: `${productPrice.priceDetail.currencySymbol}${productPrice.sixInstallementPrice} X 6 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice})` },
          { id: 9, name: `${productPrice.priceDetail.currencySymbol}${productPrice.nineInstallementPrice} X 9 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice})` },
          { id: 12, name: `${productPrice.priceDetail.currencySymbol}${productPrice.twelveInstallementPrice} X 12 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice})` }
        )
    )

    Object.keys(productPriceWithDiscount).length > 0 && (
      //Eğer candidatePriceDetail objesi varsa dropdown'da uygulanan indirimlere ek olarak ikinci kayıt indirimi uygulanmış olarak gösteriyoruz
      productPrice.candidatePriceDetail
        ? priceWithDiscountArrayList.push(
          { id: 1, name: `${strings.member.member_detail.sales_operations.one_shot} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` },
          { id: 3, name: `₺${productPriceWithDiscount.threeInstallementPrice} X 3 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` },
          { id: 6, name: `₺${productPriceWithDiscount.sixInstallementPrice} X 6 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` },
          { id: 9, name: `₺${productPriceWithDiscount.nineInstallementPrice} X 9 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` },
          { id: 12, name: `₺${productPriceWithDiscount.twelveInstallementPrice} X 12 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.candidatePriceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` }
        )
        : priceWithDiscountArrayList.push(
          { id: 1, name: `${strings.member.member_detail.sales_operations.one_shot} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` },
          { id: 3, name: `₺${productPriceWithDiscount.threeInstallementPrice} X 3 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` },
          { id: 6, name: `₺${productPriceWithDiscount.sixInstallementPrice} X 6 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` },
          { id: 9, name: `₺${productPriceWithDiscount.nineInstallementPrice} X 9 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` },
          { id: 12, name: `₺${productPriceWithDiscount.twelveInstallementPrice} X 12 ${strings.member.member_detail.sales_operations.payment_option} (${productPrice.priceDetail.currencySymbol}${productPrice.priceDetail.totalPrice} yerine ₺${productPriceWithDiscount.discountedTotalPrice})` }
        )
    )

    setProductPriceList(productPriceList => priceArrayList);
    setProductPriceListWithDiscount(productPriceListWithDiscount => priceWithDiscountArrayList);

  }, [productPrice, productPriceWithDiscount])

  //Seçilen indirimlere göre toplam indirim tutarı hesaplanıyor.
  useEffect(() => {
    if (Object.keys(selectedHourDiscount).length > 0 && selectedHourDiscount.filter(i => i.type === "Hour").length > 1){
      setSelectedHourDiscount([]);
      showTopAlert(strings.errors.same_campaign_type_error, "error");
    }

    let totalDiscountRate = 0.0;
    let tmpAllApliedDiscounts = [];

    if (campaign.id !== -1) {
      campaignListWithDetail.map((item) => {
        if (campaign.id === item.campaignId) {
          tmpAllApliedDiscounts.push(item);
          totalDiscountRate += item.discountRate;
        }
      })
    }
    if (moneyboxDiscount.id !== -1) {
      allDiscountDetailsList.map((item) => {
        if (moneyboxDiscount.id === item.campaignId) {
          tmpAllApliedDiscounts.push(item);
          totalDiscountRate += item.discountRate;
        }
      })
    }
    if (referenceDiscount.id !== -1) {
      allDiscountDetailsList.map((item) => {
        if (referenceDiscount.id === item.campaignId) {
          tmpAllApliedDiscounts.push(item);
          totalDiscountRate += item.discountRate;
        }
      })
    }
    if (otherDiscounts.length > 0) {
      allDiscountDetailsList.map((item) => {
        otherDiscounts.map((otherDiscount) => {
          if (otherDiscount.id === item.campaignId) {
            tmpAllApliedDiscounts.push(item);
            totalDiscountRate += item.discountRate;
          }
        })
      })
    }
    if (selectedHourDiscount.length > 0) {
      allDiscountDetailsList.map((item) => {
        selectedHourDiscount.map((hourDiscount) => {
          if (hourDiscount.id === item.campaignId) {
            tmpAllApliedDiscounts.push(item);
            totalDiscountRate += item.discountRate;
          }
        })
      })
    }
    
    //Herhangi bir indirim seçildi ise indirimli fiyat hesaplaması için servise istek atılıyor.
    if (campaign.id !== -1 || moneyboxDiscount.id !== -1 || referenceDiscount.id !== -1 || otherDiscounts.length > 0 || selectedHourDiscount.length > 0) {
      let model = {
        totalPrice: selectedProduct.priceDetail?.discountPriceWithoutFormat || "",
        totalDiscountRate: totalDiscountRate,
        brandId: brandId
      };

      calculateProductPriceWithDiscount(model);

      setAllAppliedDiscounts(tmpAllApliedDiscounts)
      setTotalDiscount(totalDiscount => totalDiscountRate);
    }

  }, [campaign, moneyboxDiscount, referenceDiscount, otherDiscounts, selectedHourDiscount])

  //Paket detayları getiriliyor.
  useEffect(() => {
    if (productCategory.id !== -1 && month.id !== -1 && minute.id !== -1 && dayOfWeek.id !== -1) {

      let model = {
        brandId: brandId,
        memberStatus: memberStatus,
        categoryId: productCategory.id,
        productDayCount: dayOfWeek.id,
        productMonthId: month.id,
        productMinute: minute.id
      };
      getSalesProductDetails(model);
    } else {
      setSelectedProduct({});
    }
  }, [month, minute, dayOfWeek])

  //2 ve 3 günlük paketlerde 40 ve üzeri dakikalarda ders olmadığı için üst değerler alınmıyor
  useEffect(() => {
    setCampaign(firstCampaign);
    setCampaignList([firstCampaign]);

    if (dayOfWeek.id === 2 || dayOfWeek.id === 3) {
      let tmpMinute = brandId === 1 ? tempMinuteList.filter(m => m.id < 50) : tempMinuteList;
      if (minute.id === 50 && brandId === 1) {
        setMinute(firstMinute);
      }
      setMinuteList([firstMinute, ...tmpMinute]);
    } else {
      setMinuteList([firstMinute, ...tempMinuteList]);
    }

    if (dayOfWeek.id === -1) {
      setMinute(firstMinute);
      setMinuteList([firstMinute]);
    }
  }, [dayOfWeek])

  //Seçili ürün değişince ürüne özel kampanya ve indirimleri getiriyoruz
  useEffect(() => {

    if (Object.keys(selectedProduct).length > 0) {
      getCampaignTypeDetails(selectedProduct.productId, salesType.id);
      let model = {
        memberId: memberId,
        moneyboxDiscountLimit: 100 - selectedProduct.priceDetail?.discountRate,
        productId: selectedProduct.productId,
        selectedCampaignId: null,
        salesTypeId: salesType.id
      };
      getDiscountsByMemberId(model);
    }
  }, [selectedProduct, salesType, campaign])

  //Paket seçme kısmı değiştiğinde süre kısımları sıfırlanır.
  useEffect(() => {
    //Ürün kategorisi değiştiğinde tüm dakika, gün ve ay bilgilerini sıfırlıyoruz
    setMonth(firstMonth);
    setDayOfWeek(firstDayOfWeek);
    setMinute(firstMinute);
    setMonthList([firstMonth]);
    setDayOfWeekList([firstDayOfWeek]);
    setMinuteList([firstMinute]);
  }, [productCategory, language])

  useEffect(() => {
    if (productCategory.id !== -1) {
      getSalesProducts(productCategory.id, brandId, salesType)
    }
  }, [productCategory, salesType])

  //Ödeme tutarının başlangıçta otomatik olarak 12 taksit seçili gelmesini sağlayıyoruz
  useEffect(() => {
    if (minute.id !== -1) {
      (productPriceListWithDiscount.length > 0 && (campaign.id !== -1 || moneyboxDiscount.id !== -1 || referenceDiscount.id !== -1 || otherDiscounts.length > 0 || selectedHourDiscount.length > 0))
        ? setPaymentPrice(paymentPrice => productPriceListWithDiscount[productPriceListWithDiscount.length - 1] || firstPaymentPrice)
        : setPaymentPrice(paymentPrice => productPriceList[productPriceList.length - 1] || firstPaymentPrice)
    } else
      setPaymentPrice(firstPaymentPrice);
  }, [minute, productPriceListWithDiscount, productPriceList, campaign, moneyboxDiscount, referenceDiscount, otherDiscounts, selectedHourDiscount])

  //Ödeme tutarının başlangıçta otomatik olarak 12 taksit seçili gelmesini sağlayıyoruz
  useEffect(() => {
    if (minute.id !== -1) {
      (productPriceListWithDiscount.length > 0 && (campaign.id !== -1 || moneyboxDiscount.id !== -1 || referenceDiscount.id !== -1 || otherDiscounts.length > 0 || selectedHourDiscount.length > 0))
        ? setPaymentPrice(paymentPrice => productPriceListWithDiscount[productPriceListWithDiscount.length - 1] || firstPaymentPrice)
        : setPaymentPrice(paymentPrice => productPriceList[productPriceList.length - 1] || firstPaymentPrice)
    } else
      setPaymentPrice(firstPaymentPrice);
  }, [minute, productPriceListWithDiscount, productPriceList, campaign, moneyboxDiscount, referenceDiscount, otherDiscounts, selectedHourDiscount])

  //Dil değiştiğinde seçili değerler sıfırlanıyor
  useEffect(() => { setSalesType(firstSalesType) }, [language])

  useEffect(() => {
    if (brandId !== -1) {
      (async () => {
        let tempDiscountTypeList = [];

        // Aile indirimi getiren hizmet
        const resultFamilyDiscount = await GetFamilyDiscountRates(brandId);
        if(resultFamilyDiscount.status === 200){
          const resultContent = JSON.parse(resultFamilyDiscount.content);
          tempDiscountTypeList.push({
            id: resultContent.campaignId,
            name: resultContent.campaignTitle,
            discountRate: resultContent.discountRate
          });
        }

        // Saatlik indirimler getiren hizmet
        const resultHourlyDiscount = await GetHourlyDiscountRates(getLanguageTypeByLanguageKey(language));
        if (resultHourlyDiscount.status === 200) {
          const resultContent = JSON.parse(resultHourlyDiscount.content);
          if (brandId === Brands.KO) {
            resultContent.forEach(result => {
              tempDiscountTypeList.push({
                id: result.minute,
                name: result.title + " - %" + result.discountRate,
                discountRate: result.discountRate,
                type: "Hour",
              });
            });
          } else {
            const fortyEightDiscountMinute = 2880;
            const specificItem = resultContent.find(result => result.minute === fortyEightDiscountMinute);
            if (specificItem) {
              tempDiscountTypeList.push({
                id: specificItem.minute,
                name: specificItem.title + " - %" + specificItem.discountRate,
                discountRate: specificItem.discountRate,
                type: "Hour",
              });
            }
          }

          setHourDiscountList(tempDiscountTypeList);
        }
      })();
    }
  }, [language, brandId]);

  //Satış tipi değiştiğinde tüm bilgiler sıfırlanıyor.
  useEffect(() => {
    setProductCategory(firstProductCategory);
    setCampaign(firstCampaign);
    setMoneyboxDiscount(firstMoneyboxDiscount);
    setReferenceDiscount(firstReferenceDiscount);
    setSelectedHourDiscount([]);
    setOtherDiscounts([]);

    setProductCategoryList([firstProductCategory]);
    setCampaignList([firstCampaign]);
    setMoneyboxDiscountList([firstMoneyboxDiscount]);
    setReferenceDiscountList([firstReferenceDiscount]);
    setOtherDiscountsList([]);
  }, [salesType, language])

  //Template dolduruluyor.
  useEffect(() => {
    if (tmpContent !== null) {
      let packageText = "";
      let additionalInformationText = "";
      let employeeInformationsText = "";

      //Seçilen paket bilgilerini template'e yazdırıyoruz.
      if (selectedPackages.length > 0) {
        selectedPackages.map((item, index) => {
          let arrayItem = `<p id="package_${index}">${item}</p>`
          packageText += arrayItem;
          if (index !== selectedPackages.length - 1) {
            packageText += "<hr class='my-2' />";
          }
        })
      }

      //Ek bilgilendirme kısmını template'e yazdırıyoruz.
      if (selectedAdditionalInformation.length > 0) {
        //Banka havale bilgileri gönder kısmı seçili değilse, seçilen text'i template yazdırıyoruz.
        selectedAdditionalInformation.map((item, index) => {
          if (item.id !== 5) {
            let arrayItem = `<p id="additional_${index}">${item[language]}</p>`
            additionalInformationText += arrayItem;
          }
        })

        //Ek bilgilendirme kısmında seçilen alanın 'Banka havale bilgilerini gönder' olup olmadığını kontrol ediyoruz.
        const filteredSelectedAdditionalInformation = selectedAdditionalInformation.find(item => item.id === 5);
        //Banka havale bilgileri gönder seçildiyse servise istek atıp banka bilgilerini template içerisine ekliyoruz.
        if (filteredSelectedAdditionalInformation?.id === 5 && brandId !== 0) {
          getBankInformations(brandId);

          let bankInfoArray = [];

          bankInformations.length > 0 && bankInformations.map((item) => {
            let arrayItem = `<p>${item.bankName} - ${item.branchName} - ${item.iban}</p>`
            additionalInformationText += arrayItem;
          })
        }
      }

      //Danışman bilgilerini template içerisine ekliyoruz.
      if (Object.keys(employeeInformations).length > 0) {
        employeeInformationsText = `<p>${employeeInformations.name} / ${employeeInformations.corporateEmail} / ${employeeInformations.corporatePhone}</p>`
      }

      if (tmpContent)
        setContent(tmpContent
          .replace("{{{packages}}}", packageText)
          .replace("{{{additionalInformations}}}", additionalInformationText.length === 0 ? "-" : additionalInformationText)
          .replace("{{{employeeInformations}}}", employeeInformationsText)
          .replace("{{year}}", new Date().getFullYear())
        );

      if (tmpWhatsappContent)
        setWhatsappContent(tmpWhatsappContent
          .replace("{{{packages}}}", packageText)
          .replace("{{{additionalInformations}}}", additionalInformationText.length === 0 ? "-" : additionalInformationText)
          .replace("{{{employeeInformations}}}", employeeInformationsText)
          .replace("{{year}}", new Date().getFullYear())
        );
    }
  }, [tmpContent, tmpWhatsappContent, selectedPackages, selectedAdditionalInformation, employeeInformations, bankInformations])

  return (
    <>
      <PageTitle title={strings.member.member_detail.header.content.send_offer} />
      <p ref={topRef} tabIndex={-1} className="h-0"></p>
      <div className="p-3 pt-0">
        <div className="flex flex-col md:flex-row gap-10">
          <div className="w-full">
            <p className="member-nav-button member-nav-button-active max-w-max mb-5">
              {strings.member.member_detail.header.education_packages}
            </p>
            <div className="md:!max-w-[480px] flex flex-col gap-5">
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.sell_type} <span className="text-red">*</span>
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={salesTypeList}
                  selectedData={salesType}
                  setSelectedData={setSalesType}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.education_package} <span className="text-red">*</span>
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={[firstProductCategory, ...productCategoryList]}
                  selectedData={productCategory}
                  setSelectedData={setProductCategory}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.education_time} <span className="text-red">*</span>
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={monthList}
                  selectedData={month}
                  setSelectedData={setMonth}
                  isDisabled={productCategory.id === -1 ? true : false}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.weekly_lesson_count} <span className="text-red">*</span>
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={dayOfWeekList}
                  selectedData={dayOfWeek}
                  setSelectedData={setDayOfWeek}
                  isDisabled={month.id === -1 ? true : false}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.lesson_time} <span className="text-red">*</span>
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={minuteList}
                  selectedData={minute}
                  setSelectedData={setMinute}
                  isDisabled={dayOfWeek.id === -1 ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <nav className="flex gap-x-5 sm:gap-x-10 flex-wrap mb-5">
              <button
                ref={discountButtonRef}
                onClick={() => {
                  activeDiscountTab(discountButtonRef, additionalInformationButtonRef)
                  openDiscountTab(discountTabRef, additionalInformationTabRef)
                }}
                className="member-nav-button member-nav-button-active"
              >
                {strings.member.member_detail.inform_operations.discounts}
              </button>
              <button
                ref={additionalInformationButtonRef}
                onClick={() => {
                  activeAdditionalInformationTab(additionalInformationButtonRef, discountButtonRef)
                  openAdditionalInformationTab(discountTabRef, additionalInformationTabRef)
                }}
                className="member-nav-button"
              >
                {strings.member.member_detail.inform_operations.additional_information_title}
              </button>
            </nav>
            <div
              ref={discountTabRef}
              id="offer_discounts"
              className="step-tab md:!max-w-[480px] flex flex-col gap-5"
            >
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2" htmlFor="moneybox_offer">
                  {strings.member.member_detail.inform_operations.choose_campaign}
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={campaignList}
                  selectedData={campaign}
                  setSelectedData={setCampaign}
                  isDisabled={(campaignList.length === 1 && campaignList[0].id === -1)}
                  tooltip={(campaignList.length === 1 && campaignList[0].id === -1 && dayOfWeek.id !== -1) ? strings.errors.campaign_not_found : ""}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.moneybox_discount}
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={moneyboxDiscountList}
                  selectedData={moneyboxDiscount}
                  setSelectedData={setMoneyboxDiscount}
                  isDisabled={(minute.id === -1 || (moneyboxDiscountList.length === 1 && minute.id !== -1))}
                  tooltip={(moneyboxDiscountList.length === 1 && minute.id !== -1) ? strings.member.member_detail.inform_operations.moneybox_discount_not_found : ""}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.reference_discount}
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={referenceDiscountList}
                  selectedData={referenceDiscount}
                  setSelectedData={setReferenceDiscount}
                  isDisabled={(salesType?.id !== 1 || minute.id === -1 || (referenceDiscountList.length === 1 && minute.id !== -1))}
                  tooltip={(referenceDiscountList.length === 1 && minute.id !== -1) ? strings.member.member_detail.inform_operations.reference_discount_not_found : ""}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.other_discounts}
                </label>
                <DropdownMultiSelect
                  showChooseAll="notShow"
                  setAllObjectToState={true}
                  title={strings.detail_search_titles.discounts}
                  data={otherDiscountsList || []}
                  selectedData={otherDiscounts}
                  setSelectedData={setOtherDiscounts}
                  randomId="discountListMS"
                  classnames="md:!max-w-full"
                  inputPlaceholder={strings.detail_search_titles.search_discount}
                  isDisabled={(minute.id === -1 || (otherDiscountsList.length === 0 && minute.id !== -1))}
                  tooltip={(otherDiscountsList.length === 0 && minute.id !== -1) ? strings.member.member_detail.inform_operations.discount_not_found : ""}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.discounts}
                </label>
                <DropdownMultiSelect
                  showChooseAll="notShow"
                  setAllObjectToState={true}
                  title={strings.detail_search_titles.discounts}
                  data={hourDiscountList || []}
                  selectedData={selectedHourDiscount}
                  setSelectedData={setSelectedHourDiscount}
                  randomId="hourDiscountList"
                  classnames="md:!max-w-full"
                  inputPlaceholder={strings.detail_search_titles.search_discount}
                  isDisabled={(minute.id === -1 || (hourDiscountList.length === 0 && minute.id !== -1))}
                  tooltip={(hourDiscountList.length === 0 && minute.id !== -1) ? strings.member.member_detail.inform_operations.discount_not_found : ""}
                />
              </div>
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.member.member_detail.inform_operations.payment_price} <span className="text-red">*</span>
                </label>
                <Dropdown
                  classnames="md:max-w-full"
                  data={
                    (campaign.id !== -1 || moneyboxDiscount.id !== -1 || referenceDiscount.id !== -1 || otherDiscounts.length > 0 || selectedHourDiscount.length > 0)
                      ? productPriceListWithDiscount
                      : productPriceList
                  }
                  selectedData={paymentPrice}
                  setSelectedData={setPaymentPrice}
                  isDisabled={minute.id === -1}
                />
              </div>
            </div>
            <div
              ref={additionalInformationTabRef}
              id="offer_information"
              className="step-tab md:!max-w-[480px] hidden"
            >
              <div className="flex flex-col gap-4">
                {additionalInformationList.length > 0 && additionalInformationList.map((item, index) => (
                  <label key={index} className="flex justify-start items-start">
                    <input
                      id={`additional_${index + 1}`}
                      type="checkbox"
                      className="hidden"
                      onChange={() => handleChoose(item)}
                    />
                    <label
                      htmlFor={`additional_${index + 1}`}
                      className={`${handleSelectedCheckbox(item)} mr-2 w-5 h-5 flex-shrink-0 rounded-[3px] cursor-pointer duration-500 flex items-center justify-center`}
                    >
                      <span className={classNames('text-inherit text-[20px] material-symbols-outlined animate-fadeIn font-bold', { 'hidden': !selectedAdditionalInformation.includes(item) })}>
                        done
                      </span>
                    </label>
                    <p className="cursor-default">{item[language]}</p>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        <button
          className="button search-button px-10 text-white bg-blue border-blue mx-auto mt-7"
          onClick={handleAddPackage}
        >
          <p>{strings.button.add_package}</p>
        </button>
      </div>

      {selectedPackages.length > 0 && (
        <>
          <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.parameter}</p><hr />
          <div className="flex flex-wrap gap-3 my-3">
            {selectedPackages.map((item, index) => (
              <span key={index} className="relative px-4 py-2 rounded-[10px] pr-7 bg-blue text-white">
                <span dangerouslySetInnerHTML={{ __html: item }} />
                <button className="absolute right-2 top-1 flex items-center text-[12px]" onClick={() => deletePackage(index)}>X</button>
              </span>
            ))}
          </div>

          {tmpContent && (
            <>
              <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.email_content}</p>
              <div
                id="contentDiv"
                className="w-full h-[350px] overflow-y-scroll"
                dangerouslySetInnerHTML={{ __html: content !== null ? content : "" }}
              />
            </>
          )}

          {/*
             DecodeURI ile url encoded ile gelen templateler kullanıcıya gösterilirken decode ediliyor.
           */}
          {tmpWhatsappContent && (
            <div className="mt-5">
              <p className="text-[13px] font-bold mb-2">{strings.member.member_detail.inform_operations.whatsapp_content}</p>
              <div
                id="whatsappContentDiv"
                className="w-full h-[150px] overflow-y-scroll"
                dangerouslySetInnerHTML={{ __html: whatsappContent !== null ? customDecodeURI(whatsappContent) : "" }}
              />
            </div>
          )}

          <div className="py-4 flex flex-col xl:flex-row gap-5 xl:gap-10 xl:items-end">
            <div className="flex flex-col gap-5 xl:gap-10 md:flex-row w-full md:items-center">
              <div className="w-full flex flex-col">
                <label className="text-[13px] font-bold mb-2">
                  {strings.form_info.email}
                </label>
                <Input
                  type="text"
                  value={isItSpecified(currentEmail, notSpecified)}
                  isDisabled={currentEmail === "Belirtilmedi"}
                  isReadOnly={true}
                />
              </div>
              <div className="w-full flex flex-col">
                <div className="flex flex-row gap-1">
                  <label className="text-[13px] font-bold mb-2">
                    {strings.form_info.other_email}
                  </label>
                  <div className="group relative">
                    <span className="material-symbols-outlined text-[19px] align-top">
                      info
                    </span>
                    <div className="tooltip-top -top-[55px]">
                      <p>{strings.member.member_detail.inform_operations.other_email_information}</p>
                    </div>
                  </div>
                </div>
                <Input
                  type="text"
                  placeholder={strings.member.member_detail.inform_operations.other_email_placeholder}
                  onChange={(e) => setOtherEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="flex justify-center gap-2">
              <Button
                text={sendEmailButtonLoading ? <LoadingSpinner /> : strings.button.send_email_button}
                disabled={sendEmailButtonLoading}
                classnames={`bg-blue border-blue px-10 w-[177px] 
                  ${sendEmailButtonLoading ? "opacity-70 hover:opacity-70 cursor-not-allowed" : ''}
                `}
                action={handleEmail}
              />
              <Button
                text={strings.button.send_from_whatsapp_button}
                classnames={`bg-blue w-[177px] border-blue !px-10 ${!tmpWhatsappContent ? "opacity-70 hover:opacity-70 cursor-not-allowed" : ''} `}
                disabled={!tmpWhatsappContent}
                action={handleSendWhatsappMessage}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SendOffer;
