import { getCookie } from "ko-basic-cookie";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { showTopAlert } from "../../components";
import { decodeToken, getSelectedLanguageByBrandId, validateEmail } from "../../components/Utility";
import { cookieName, ServiceErrorMessages, StorageKeys } from "../../consts/Constants";
import { EmployeeDimensionProperties } from "../../consts/DataLayer";
import useAuth from "../../hooks/Log/useAuth";
import useDataLayer from "../../hooks/Log/useDataLayer";
import { useLocalization } from "../../hooks/Utils/useLocalization";
import { getButtonPermission } from "../../rights/Utility";
import { url } from "../../routes/utility";
import { CreateSession as CreateAlotechSession } from "../../services/Alotech/AlotechService";
import { EmployeeLogin } from "../../services/Auth/AuthService";
import { CreateSession as CreateEsneksSession } from "../../services/Esneks/EsneksService";
import { alotechLogin } from "../../store/AlotechSlice";
import { employeeLogin } from "../../store/EmployeeSlice";
import { esneksLogin } from "../../store/EsneksSlice";
import { changeLanguage } from "../../store/LocalizationSlice";

const Login = () => {

    const strings = useLocalization();
    const dataLayer = useDataLayer();
    const { checkPermission } = useAuth();
    const loginAuthorityRoles = getButtonPermission({ group: "panelLogin", name: "login" });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const redirectPathname = location?.state?.pathname || "/dashboard"

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false)

    const inputRef = useRef();
    const visibilityRef = useRef();
    const visibilityOffRef = useRef();
    const emailErrorRef = useRef();
    const passwordErrorRef = useRef();

    const token = getCookie(cookieName) || false;

    const emailHandle = e => {
        setEmail(email => e.target.value);
    };

    const passwordHandle = e => {
        setPassword(password => e.target.value);
    };

    const checkPassword = pass => {
        return (pass.length >= 4);
    }

    const togglePassword = () => {
        visibilityRef.current.classList.toggle('hidden');
        visibilityOffRef.current.classList.toggle('hidden');
        inputRef.current.type = inputRef.current.type === "password" ? "text" : "password"
    }

    /*
        * Alotech session oluşturuluyor.
        * Bu session ile birlikte click2call özelliğini kullanabileceğiz.
    */
    const createAlotechSession = async (email) => {
        try {
            const result = await CreateAlotechSession(email);

            if(result.login === true) {
                const alotechToken = result.session;
                dispatch(alotechLogin({ alotechToken }));
            }
        } catch (e) {}
    }

    /*
        * Esneks session oluşturuluyor.
        * Bu session ile birlikte click2call özelliğini kullanabileceğiz.
    */
    const createEsneksSession = async () => {
        try {
            const result = await CreateEsneksSession();
            
            if(result.success) {
                const esneksToken = result.data.token;
                dispatch(esneksLogin({ esneksToken }));
            }
            
        } catch (e) {}
    }

    const submitHandle = async e => {
        e.preventDefault();

        //tüm hataları sıfırla
        passwordErrorRef.current.innerText = '';
        emailErrorRef.current.innerText = '';

        const emailStatus = validateEmail(email);
        const passwordStatus = checkPassword(password);

        if (emailStatus && passwordStatus) {
            setLoading(loading => true)

            const result = await EmployeeLogin(email, password);

            //Giriş başarılı ise
            if (result.status === 200) {
                const token = result.content;
                const employee = token && decodeToken(token);
                const permission = checkPermission(loginAuthorityRoles, +employee.EmployeeTypeId);

                console.log(employee);

                // Giriş yapan kullanıcının sistem için yetkisi varsa login işlemini yapıyoruz.
                // Yetkisi yok ise 'Sisteme giriş için yetkiniz yok' hatası fırlatıyoruz.
                if(permission) {
                    //login işlemini gerçekleştirir
                    dispatch(changeLanguage(getSelectedLanguageByBrandId(+employee.BrandId)))
                    dispatch(employeeLogin({ token }));

                    //Giriş yapılırken click2call için alotech session oluşturuluyor.
                    await createAlotechSession(email);

                    //Giriş yapılırken esneks session oluşturuluyor.
                    employee?.AgentNumber && employee?.AgentNumber.length > 0
                        && await createEsneksSession();

                    //Data Layer Logged
                    dataLayer.useEmployeeDimension(EmployeeDimensionProperties.EMPLOYEE_EMAIL, employee?.EmployeeId);

                    localStorage.removeItem(StorageKeys.ReminderLocalStorageKey);

                    navigate(redirectPathname);
                } else {
                    showTopAlert(strings.errors.not_authorized_for_system, "error");
                }
            } else {
                //Giriş başarısız ise
                if(ServiceErrorMessages.EMAIL_OR_PASSWORD_WRONG === result.content){
                    showTopAlert(strings.errors.email_or_password_wrong, "error");
                } else {
                    showTopAlert(strings.errors.an_error_occurred, "error");
                }
            }

            setLoading(loading => false);
        } else {
            // hata varsa ekrana yaz
            passwordErrorRef.current.innerText = !passwordStatus ? strings.errors.password_error : '';
            emailErrorRef.current.innerText = !emailStatus ? strings.errors.email_error : '';
        }
    }

    //login kontrol
    useEffect(() => {
        if (token) {
            navigate(url("dashboard"))
        }
    }, []);

    return (
        <>
            <form className="w-full flex justify-center" onSubmit={submitHandle}>
                <div
                    className="w-[400px] animate-fadeIn p-[25px] md:py-[60px] rounded-[5px] block bg-white border border-[#ebecec] shadow-form mx-5 mt-5 md:mt-0">
                    <h3 className="font-bold text-center text-[19px] md:mb-[25px]">{strings.auth.login.title}</h3>
                    <div className="login-form">
                        <div className="flex flex-col px-[10px] pb-2">
                            <label className="text-[85%]" htmlFor="email" >{strings.auth.login.your_email}</label>
                            <input id="email" type="text" className="form-input" name="email" onChange={emailHandle} />
                            <span ref={emailErrorRef} className="text-danger field-validation-valid"></span>
                        </div>
                        <div className="flex flex-col px-[10px] pt-[5px] relative">
                            <label className="text-[85%]" htmlFor="password">{strings.auth.login.your_password}</label>
                            <input ref={inputRef} id="password" type="password" className="form-input pr-10" name="password" onChange={passwordHandle} />
                            <span ref={visibilityRef} onClick={togglePassword}
                                className="toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility
                            </span>
                            <span ref={visibilityOffRef} onClick={togglePassword}
                                className="hidden toggle-password mt-[11px] material-symbols-outlined absolute right-5 top-[43px] -translate-y-1/2 text-xl cursor-pointer">
                                visibility_off
                            </span>
                            <span ref={passwordErrorRef} className="text-danger field-validation-valid" ></span>
                        </div>
                        <div className="px-[10px] pt-[10px] md:pt-[25px]">
                            <button type="submit" disabled={loading} className={`${loading ? "!opacity-[70%] cursor-progress" : ""} btn btn-primary w-full`}>
                                <div>
                                    {loading ?
                                        <div className="inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-solid border-current border-r-transparent align-[-0.25em] motion-reduce:animate-[spin_1.5s_linear_infinite]"></div>
                                        : strings.auth.login.login_button}
                                </div>
                            </button>
                        </div>
                        <div className="flex justify-center px-3 pb-2 pt-4">
                            <Link to={url("auth.forgotpassword")} type="button" className="text-blue hover:underline">{strings.auth.login.forgot_password}</Link>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Login