import { fetchApi } from '../index';

export const CreateSession = async () => {

    const url = '/esneks/login/index.php';

    const payload = {};

    return await fetchApi(url, payload);
}

export const Click2Call = async (model) => {

    const url = '/esneks/make_call/index.php';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}