import React, { useCallback, useEffect, useState, useRef } from "react";
import { Ai } from "../../../../../../assets/svg";
import { Loading } from "../../../../../../components";
import { AIKeys, SalesAiType, ServiceErrorMessages } from "../../../../../../consts/Constants";
import useAiRouting from "../../../../../../hooks/Utils/useAiRouting";
import { useLocalization } from "../../../../../../hooks/Utils/useLocalization";
import { GetAiHistoryToAiAnalysis, GetMemberAiSalesAnalysis } from "../../../../../../services/Member/MemberSalesAiAnalysis";

const MemberSalesAiAnalysis = ({ memberId, showAiDemoAnalysis, aiUseCaseSessionExists }) => {

    const strings = useLocalization();

    const levelTestUseCaseId = useAiRouting(AIKeys.LEVEL_FINDER);
    const interviewProUseCaseId = useAiRouting(AIKeys.INTERVIEW_PR0);

    const firstVisit = useRef(true);

    const [loading, setLoading] = useState({
        demoAnalysis: false,
        levelTest: false,
        interviewPro: false
    });

    const [error, setError] = useState({
        demoAnalysis: false,
        levelTest: false,
        interviewPro: false
    });

    const [salesAnalysis, setSalesAnalysis] = useState("");
    const [levelTestAnalysis, setLevelTestAnalysis] = useState("");
    const [interviewProAnalysis, setInterviewProAnalysis] = useState("");

    const getMemberAiSalesAnalysis = useCallback(async (memberId) => {
        if (loading.demoAnalysis) return;

        setLoading(prev => ({ ...prev, demoAnalysis: true }));
        setError(prev => ({ ...prev, demoAnalysis: false }));

        const result = await GetMemberAiSalesAnalysis(memberId);

        if (result.status === 200) {
            setSalesAnalysis(result.content);
        } else if (ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content
            || ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content
        ) {
            setError(prev => ({ ...prev, demoAnalysis: true }));
        }

        setLoading(prev => ({ ...prev, demoAnalysis: false }));
    }, []);

    const getAiHistoryToAiAnalysis = useCallback(async (memberId, salesAiType, useCaseId) => {
        const loadingKey = salesAiType === SalesAiType.LEVEL_TEST ? 'levelTest' : 'interviewPro';
        if (loading[loadingKey]) return;

        setLoading(prev => ({ ...prev, [loadingKey]: true }));
        setError(prev => ({ ...prev, [loadingKey]: false }));

        const result = await GetAiHistoryToAiAnalysis(memberId, salesAiType, useCaseId);
        
        if (result.status === 200) {
            if (salesAiType === SalesAiType.LEVEL_TEST) {
                setLevelTestAnalysis(result.content);
            } else if (salesAiType === SalesAiType.INTERVIEW_PRO) {
                setInterviewProAnalysis(result.content);
            }
        } else if (ServiceErrorMessages.AI_ANALYSIS_GAVE_ERROR === result.content
            || ServiceErrorMessages.AI_ANALYSIS_NOT_FOUND === result.content
        ) {
            setError(prev => ({ ...prev, [loadingKey]: true }));
        }
        
        setLoading(prev => ({ ...prev, [loadingKey]: false }));
    }, []);

    useEffect(() => {
        if (firstVisit.current) {
            firstVisit.current = false;
        } else {
            if (showAiDemoAnalysis) {
                getMemberAiSalesAnalysis(memberId);
            }
            if (aiUseCaseSessionExists.levelTestUseCaseExisting) {
                getAiHistoryToAiAnalysis(memberId, SalesAiType.LEVEL_TEST, levelTestUseCaseId);
            }
            if (aiUseCaseSessionExists.interviewProUseCaseExisting) {
                getAiHistoryToAiAnalysis(memberId, SalesAiType.INTERVIEW_PRO, interviewProUseCaseId);
            }
        }
    }, [memberId, showAiDemoAnalysis, aiUseCaseSessionExists, levelTestUseCaseId, interviewProUseCaseId, getMemberAiSalesAnalysis, getAiHistoryToAiAnalysis]);

    return (
        <div className="mt-6 px-3">
            <div className="flex flex-col gap-3">
                {/* AI Demo Satış Sistemi Yorumu */}
                {showAiDemoAnalysis && 
                    <AnalysisSection
                        title={strings.member.member_detail.profile.sales_analysis.sales_analysis_title}
                        loading={loading.demoAnalysis}
                        analysis={salesAnalysis}
                        notFoundMessage={strings.member.member_detail.profile.sales_analysis.sales_analysis_not_found}
                        tryAgainMessage={strings.button.try_again}
                        error={error.demoAnalysis}
                        onRetry={() => getMemberAiSalesAnalysis(memberId)}
                    />
                }
                
                {/* AI Level Test Satış Sistemi Yorumu */}
                {aiUseCaseSessionExists.levelTestUseCaseExisting && 
                    <AnalysisSection
                        title={strings.member.member_detail.profile.sales_analysis.level_test_title}
                        loading={loading.levelTest}
                        analysis={levelTestAnalysis}
                        notFoundMessage={strings.member.member_detail.profile.sales_analysis.level_test_not_found}
                        tryAgainMessage={strings.button.try_again}
                        error={error.levelTest}
                        onRetry={() => getAiHistoryToAiAnalysis(memberId, SalesAiType.LEVEL_TEST, levelTestUseCaseId)}
                    />
                }

                {/* AI Mülakat Pro Satış Sistemi Yorumu */}
                {aiUseCaseSessionExists.interviewProUseCaseExisting &&
                    <AnalysisSection
                        title={strings.member.member_detail.profile.sales_analysis.interview_pro_title}
                        loading={loading.interviewPro}
                        analysis={interviewProAnalysis}
                        notFoundMessage={strings.member.member_detail.profile.sales_analysis.interview_pro_not_found}
                        tryAgainMessage={strings.button.try_again}
                        error={error.interviewPro}
                        onRetry={() => getAiHistoryToAiAnalysis(memberId, SalesAiType.INTERVIEW_PRO, interviewProUseCaseId)}
                    />
                }
            </div>
        </div>
    );
};

const AnalysisSection = ({ title, loading, analysis, notFoundMessage, tryAgainMessage, error, onRetry }) => (
    <div>
        <div className="flex items-center gap-1">
            <img src={Ai} alt="ai" width={20} height={20} />
            <p className="font-bold py-3">{title}</p>
        </div>
        <hr />
        <div className="flex flex-col px-[10px] pb-0">
            {loading && <Loading />}
            {!loading && (
                <div className="mt-3 flex items-center justify-center">
                    {error ? (
                        <button 
                            type="button"
                            className="flex items-center gap-1 ml-2 border border-dark-gray hover:bg-card h-6 max-w-max px-3 my-5 rounded-[10px]"
                            onClick={onRetry}
                        >
                            <span className="material-symbols-outlined text-[16px] font-bold">
                                sync
                            </span>
                            {tryAgainMessage}
                        </button>
                    ) : (
                        analysis.length === 0 ? (
                            <p className="text-center my-5">{notFoundMessage}</p>
                        ) : (
                            <textarea
                                readOnly={true}
                                value={analysis}
                                className="border rounded-lg outline-0 focus:border-blue py-3 px-2 w-full"
                                rows="4"
                            />
                        )
                    )}
                </div>
            )}
        </div>
    </div>
);

export default MemberSalesAiAnalysis;