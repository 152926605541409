import React from "react";
import { useSelector } from "react-redux";
import { CustomEventDescriptions, CustomEvents } from "../consts/DataLayer";
import useDataLayer from "../hooks/Log/useDataLayer";
import { useLocalization } from "../hooks/Utils/useLocalization";
import { showCenteredAlertTwoButtons, showCenteredHtmlAlert } from "./SweetAlert";
import { Click2Call as AlotechClick2Call } from "../services/Alotech/AlotechService";
import { Click2Call as EsneksClick2Call } from "../services/Esneks/EsneksService";
import useAuth from "../hooks/Log/useAuth";

const Click2Call = ({ phoneNumber, fullName }) => {
    const strings = useLocalization();
    const { employee } = useAuth();
    const dataLayer = useDataLayer();
    
    const alotechSessionKey = useSelector(state => state.alotechStore.alotechToken);
    const esneksSessionKey = useSelector(state => state.esneksStore.esneksToken);

    const formattedPhoneNumber = phoneNumber?.length > 0 ? phoneNumber.split("+9")[1] : "";
    
    const CallProvider = Object.freeze({
        ALOTECH: "alotech",
        ESNEKS: "esneks"
    });

    const EsneksCallType = Object.freeze({
        SOFT: "SOFT",
        WEB: "WEB"
    });

    const showResultAlert = (success, callProvider) => {
        const message = success
            ? strings.member.member_detail.profile.click2call_success.replace("#callProvider#", callProvider)
            : strings.member.member_detail.profile.click2call_fail;

        showCenteredHtmlAlert(
            strings.alert.title.information,
            message,
            strings.alert.centered_alert_two_buttons.confirm_text,
            success ? "success" : "error"
        );
    };

    const performAlotechCall = async () => {
        const result = await AlotechClick2Call(alotechSessionKey, formattedPhoneNumber);
        dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.CLICK_2_CALL_APPROVED);
        showResultAlert(result.success, "Alotech");
    };

    const performEsneksCall = async () => {
        const sendCallEsneks = async (callType) => {
            const model = {
                from: employee?.AgentNumber,
                to: formattedPhoneNumber,
                call: callType,
                token: esneksSessionKey
            };
            return await EsneksClick2Call(model);
        };

        let result = await sendCallEsneks(EsneksCallType.SOFT);
        
        if (result === null) {
            showResultAlert(false, "3CX");
            return;
        }

        if (result?.data.includes("Hatalı")) {
            result = await sendCallEsneks(EsneksCallType.WEB);
            showResultAlert(result.success && !result.data.includes("Hatalı"), "3CX");
        } else {
            showResultAlert(result.success, "3CX");
        }
    };

    const handleClick2Call = (callProvider) => {
        if (phoneNumber.length > 0) {
            dataLayer.useCustomEvents(CustomEvents.EMPLOYEE_EVENTS, CustomEventDescriptions.CLICK_2_CALL);

            showCenteredAlertTwoButtons(
                strings.alert.centered_alert_two_buttons.title_confirm,
                strings.member.member_detail.profile.call_question.replace("#memberName#", fullName),
                strings.button.confirm,
                strings.button.cancel,
                () => callProvider === CallProvider.ALOTECH ? performAlotechCall() : performEsneksCall()
            );
        }
    };

    return (
        <div className="inline-flex items-center">
            {alotechSessionKey.length > 0 && (
                <CallButton
                    onClick={() => handleClick2Call(CallProvider.ALOTECH)}
                    tooltipText={strings.member.member_detail.profile.call}
                    bgColorClass="bg-green"
                    position="mr-0.5"
                />
            )}

            {esneksSessionKey.length > 0 && (
                <CallButton
                    onClick={() => handleClick2Call(CallProvider.ESNEKS)}
                    tooltipText={strings.member.member_detail.profile.call_3cx}
                    bgColorClass="bg-base-text"
                    position="ml-0.5"
                />
            )}
        </div>
    );
};

const CallButton = ({ onClick, tooltipText, bgColorClass, position }) => (
    <div className={`relative group inline-block ${position}`}>
        <button
            type="button"
            className={`material-symbols-outlined text-[16px] cursor-pointer text-white ${bgColorClass} rounded-full p-1 hover:opacity-80 transition-opacity`}
            onClick={onClick}
        >
            call
        </button>
        <div className="tooltip-top">{tooltipText}</div>
    </div>
);

export default Click2Call;